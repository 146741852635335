// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: #343a40;
}
nav a {
    display: inline-block;
    margin: 10px 20px;
}
video#videoElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(180deg);
    display: none;
    z-index: 100000;
}
body .carousel-control-next, body .carousel-control-prev {
    opacity: 1;
}
body .accordion >.card {
    overflow: visible;
}
footer {
    background-color: green;
    padding: 6% 0%;
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/General.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gDAAgD;IAChD,aAAa;IACb,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,iBAAiB;AACrB;AACA;IACI,uBAAuB;IACvB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;AACxB","sourcesContent":["header {\n    background-color: #343a40;\n}\nnav a {\n    display: inline-block;\n    margin: 10px 20px;\n}\nvideo#videoElement {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%) rotateY(180deg);\n    display: none;\n    z-index: 100000;\n}\nbody .carousel-control-next, body .carousel-control-prev {\n    opacity: 1;\n}\nbody .accordion >.card {\n    overflow: visible;\n}\nfooter {\n    background-color: green;\n    padding: 6% 0%;\n}\n.jumbotron {\n    padding: 2rem 1rem;\n    margin-bottom: 2rem;\n    background-color: #e9ecef;\n    border-radius: .3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
