// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/emoji-happy-round.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    overflow-x: hidden;
}
#point-pointer {
    position: fixed;
    z-index: 1051;
    left: auto;
    right: 50%;
    top: 50%;
    transition: top 300ms, right 300ms;
    pointer-events: none;
}
#point-pointer img {
    width: 100%;
}
#point-pointer svg {
    position: absolute;
    stroke: #eb7800;
    stroke-width: 1px;
}
#emoji-container {
    position: relative;
}
#emoji-smile {
    position: absolute;
    width: 48px;
    height: 48px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: contain;
    left: 1px;
    top: 15px;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PointPointer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,aAAa;IACb,UAAU;IACV,UAAU;IACV,QAAQ;IACR,kCAAkC;IAClC,oBAAoB;AACxB;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yDAA6D;IAC7D,4BAA4B;IAC5B,wBAAwB;IACxB,SAAS;IACT,SAAS;IACT,cAAc;AAClB","sourcesContent":["html {\n    overflow-x: hidden;\n}\n#point-pointer {\n    position: fixed;\n    z-index: 1051;\n    left: auto;\n    right: 50%;\n    top: 50%;\n    transition: top 300ms, right 300ms;\n    pointer-events: none;\n}\n#point-pointer img {\n    width: 100%;\n}\n#point-pointer svg {\n    position: absolute;\n    stroke: #eb7800;\n    stroke-width: 1px;\n}\n#emoji-container {\n    position: relative;\n}\n#emoji-smile {\n    position: absolute;\n    width: 48px;\n    height: 48px;\n    background-image: url('/public/images/emoji-happy-round.png');\n    background-repeat: no-repeat;\n    background-size: contain;\n    left: 1px;\n    top: 15px;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
