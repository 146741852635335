// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1051;
}
.loading-page {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.spinner {
    border: 10px solid transparent;
    border-top: 10px solid white;
    border-bottom: 10px solid white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoadingPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,iCAAiC;IACjC,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI,8BAA8B;IAC9B,4BAA4B;IAC5B,+BAA+B;IAC/B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kCAAkC;AACtC;AACA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".loading-page-container {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background-color: rgba(0,0,0,0.7);\n    z-index: 1051;\n}\n.loading-page {\n    position: absolute;\n    width: 100px;\n    height: 100px;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n.spinner {\n    border: 10px solid transparent;\n    border-top: 10px solid white;\n    border-bottom: 10px solid white;\n    border-radius: 50%;\n    width: 100px;\n    height: 100px;\n    animation: spin 1s linear infinite;\n}\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
